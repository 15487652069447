/* body {
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    font: 12pt "Tahoma";
    font-family: "Inter";
  }

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  } */

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  font-family: "Roboto" !important;
  background: url("./images/cert.png") no-repeat center/cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.102);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  justify-content: flex-start;
  position: relative;
  transform: translateY(10px);
}
.gradient-border {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  /* font-size: 18px;
    background: linear-gradient(to right, #ffd700, #ffa500); */
}
.font-roboto {
  font-family: "Roboto";
}
.gradient-border::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px; /* Adjust this for border thickness */
  /* background: linear-gradient(to right, 0% #fff, 100% #ffa500); */
  background: linear-gradient(
    90deg,
    rgba(224, 225, 226, 0.12097338935574231) 0%,
    rgba(224, 225, 226, 1) 49%,
    rgba(224, 225, 226, 0.14898459383753504) 100%
  );
  border-radius: 5px;
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
}

.header-cert {
  /* color: #c9ffbb; */
  color: #ffcb13;
  font-size: 36px;
  /* margin-top: "70px !important"; */
  transform: translateY(15px);
  margin-top: 25px;
  padding-bottom: 30px;
  font-weight: 700;
}

.dot-right {
  position: absolute;
  bottom: 15px;
  right: 0;
}
.dot-left {
  position: absolute;
  top: 0;
  left: 0;
}

.remove-btn-pdf {
  background: linear-gradient(to right, #66c16d 0%, #22852a 100%);
  color: #efefef;
  padding: 7px 17px;
  border-radius: 5px;
  justify-self: flex-end;
  position: fixed;
  /* right: 5%; */
  top: 0px;
  right: 50%;
  z-index: 5;
  transform: translateX(50%);
  font-size: 125%;
}
