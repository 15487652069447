@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Roboto:wght@400;700&display=swap");

.profile-page {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 55px;
  font-family: "Roboto";
  background: #f8f9fa;
  padding: 45px 0;
  min-height: 100vh;
}
.insidePage {
  width: calc(100% - 350px);
  margin-left: 300px;
  padding-bottom: 100px;
}
.insidePage .wrapper {
  width: 925px;
  margin: 0 auto;
}
.insidePage .wrapper > h1 {
  font-size: 23px;
  color: #000000;
  line-height: 50px;
  margin: 18px 0;
  text-align: center;
  font-weight: 700;
  position: relative;
}
.insidePage .wrapper > h1::before {
  position: absolute;
  content: "";
  width: 200px;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(13, 125, 140, 0.35) 0%,
    rgba(13, 13, 28, 0.68) 49%,
    rgba(13, 125, 140, 0.35) 100%
  );
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0.3;
}
.insidePage .wrapper > img {
  width: 342px;
  margin: 0 auto;
}
.insidePage .form {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  font-family: "Roboto";
}
.insidePage .form .formControl {
  display: flex;
  gap: 20px;
}
.insidePage .form .formControl.wrap {
  flex-wrap: wrap;
  justify-content: space-between;
}
.insidePage .form .formControl.wrap > div {
  display: flex;
  flex-wrap: nowrap;
  gap: 18px;
}
.insidePage .form .formControl.wrap > div .txt {
  width: 33%;
}
.w-100 {
  width: 100%;
  max-width: 100% !important;
  flex-wrap: wrap !important;
}
.insidePage .form .formControl .txt {
  width: calc(100% - 325px);
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e2e8f0;
  background: #ffffff;
  padding: 0 20px;
  color: #4f4e4e;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  transition: all 0.5s ease;
}
.insidePage .form .formControl textarea {
  height: 150px !important;
  padding-top: 10px !important;
}
.insidePage .form .formControl .txt::placeholder {
  opacity: 0.8;
}
.insidePage .form .formControl .txt:focus {
  border-color: #f53838;
}
.insidePage .form .formControl .txt.txt2 {
  width: 59%;
}
.insidePage .form .formControl .txt.txt3 {
  width: 31.3%;
}
.insidePage .form .formControl .txt.txt4 {
  width: 280px;
}
.insidePage .form .formControl .txt.txt5 {
  width: 354px;
}

.insidePage .wrapper .form label {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  line-height: 35px;
  width: 305px;
}
.insidePage .wrapper .form label.required::after {
  content: "*";
  color: #f53838;
  padding-left: 3px;
}

.insidePage .form .formControl > div > label {
  color: #6c6c6c;
  font-size: 16px;
  line-height: 0;
  font-weight: 400;
  margin-left: 5px;
}
.insidePage .wrapper .form .radio {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.insidePage .btn {
  background-image: linear-gradient(
    to right,
    rgba(115, 217, 130, 1) 0%,
    rgba(6, 108, 21, 1) 100%
  );
  border-radius: 5px;
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
  width: 225px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  background-size: 100% auto;
}
.insidePage .btn:hover {
  background-position: right center;
  background-size: 150% auto;
}
.insidePage .btn2 {
  border: 1px solid #d7d7d7;
  color: #888888;
  font-size: 23px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #e4e3e3;
  width: 225px;
  height: 50px;
  transition: all 0.5s ease;
}
.insidePage .btn2:hover {
  background: #c8c8c8;
}
.insidePage .form .formControl .group-btn {
  display: flex;
  align-items: center;
  gap: 25px;
}
.insidePage .button {
  background-color: #f53838;
  border-radius: 12px;
  width: 225px;
  height: 50px;
  font-size: 23px;
  color: #fff;
  font-weight: 700;
  transition: background-color 0.5s;
}
.insidePage .button:hover {
  background-color: #f51212;
}

.insidePage .form .social {
  display: flex;
  align-items: center;
  gap: 15px;
}
.insidePage .form .social img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.insidePage .loading-form {
  display: flex;
  justify-content: center;
  gap: 45px;
  margin-bottom: 60px;
  padding-top: 15px;
}
.insidePage .loading-form li {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #22852a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #22852a;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.insidePage .loading-form li::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 1.5px;
  background: #22852a;
  top: 50%;
  right: -45px;
}
.insidePage .loading-form li:last-child:after {
  display: none;
}
.insidePage .loading-form li.active {
  background: linear-gradient(91deg, #66c16d 0.28%, #22852a 69.91%);
  border: none;
  color: #fff;
}
.panel-profile .list-items .innovation-challenge img {
  filter: none !important;
}
.panel-profile .list-items .innovation-challenge a {
  color: #e49702 !important;
}
.insidePage .loading-form li.fill {
}
.form .formControl .box {
  display: flex;
  flex-direction: column;
  width: calc(100% - 325px);
}
.form .formControl .box2 {
  width: calc(100% - 325px);
  display: flex;
  gap: 20px;
}
.form .formControl .box .txt {
  width: 100%;
}
.form .formControl .box span {
  font-size: 14px;
  line-height: 33px;
  color: #000000;
  padding-left: 20px;
}
.form .formControl > ul {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.form .formControl > ul label {
  color: #6c6c6c !important;
  margin-left: 5px;
}

.form input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

/*panel profile page*/

.panel-profile {
  transform: translateY(-45px);
  width: 278px;
  min-height: 100vh;
  /* border-right: 2px solid #c5c5c5; */
  border-image: linear-gradient(
      to bottom,
      #5e5e5e00 0%,
      #c5c5c5 40%,
      #56565600 100%
    )
    30;
  border-width: 2px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding: 30px 30px 75px 30px;
  position: fixed;
}

.panel-profile .list-items .userAdi {
  color: #6f6d6d;
  font-size: 12px;
}
.wallet--name {
  color: #c7c7c7;
  font-size: 16px;
}
.panel-profile > img {
  width: 152px;
}
.panel-profile > .imgLogo {
  display: none;
}
.panel-profile .list-items {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  flex-grow: 1;
}
.getfreetoken {
  color: #e49702 !important;
}
.panel-profile .list-items .list-items-header {
  font-weight: 600;
  color: #000;
  position: relative;
  font-size: 10px;
  margin-bottom: 5px;
}
.panel-profile .list-items .list-items-header::after {
  content: "";
  width: 65%;
  background-color: #aeaeae;
  position: absolute;
  height: 1px;
  bottom: 2px;
  right: 2px;
}
.panel-profile .list-items li {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 8px;
  cursor: pointer;
}
.panel-profile .list-items li.active {
  background: #c7ffcb;
  border-radius: 4px;
}
.panel-profile .list-items li.active img {
  filter: unset;
}
.panel-profile .list-items li.active a {
  background-image: -webkit-linear-gradient(45deg, #22852a 100%, #66c16d 0%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.panel-profile .list-items li:hover a {
  color: #22852a;
}
.panel-profile .list-items li img {
  filter: invert(1) grayscale(1);
  transition: filter 0.5s ease;
}
.panel-profile .list-items li:hover img {
  filter: none;
}
.panel-profile .list-items a {
  color: #c7c7c7;
  font-size: 12px;
  transition: all 0.5s ease;
}

.panel-profile .list-items a.getfreetoken {
  color: #e49702 !important;
}
.panel-profile .button {
  background-image: linear-gradient(
    to right,
    rgba(115, 217, 130, 1) 0%,
    rgba(6, 108, 21, 1) 100%
  );
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  background-size: 100% auto;
}
.panel-profile .button:hover {
  background-size: 150% auto;
  background-position: right center;
}
.panel-profile .button img {
  display: none;
}
.endStep-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 38px;
}

.requset-container {
  background: #fff;
  border-radius: 14px;
  width: calc(100% - 278px);
  margin-right: 50px;
  margin-left: 300px;
}
.requset-container .head-container {
  padding: 20px 35px;
}
.requset-container .head-container .head-name {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 24px;
  color: #3f4254;
  font-weight: 600;
}
.requset-container .requsets-box {
  width: 100%;
}
.requset-container .requsets-box .head-req {
  background: #f5f8fa;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  width: 100%;
  border-bottom: 1px solid #eff2f5;
  border-top: 1px solid #eff2f5;
  padding: 10px 35px;
}
.requset-container .requsets-box .head-req > span {
  color: #3f4254;
  font-size: 16px;
  font-weight: 600;
}
.requset-container .requsets-box .head-req > span:last-child {
  text-align: center;
}

.requset-container .requsets-box .body-req .items {
  padding: 20px 35px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  width: 100%;
  border-bottom: 1px solid #eff2f5;
}
.requsets-box .body-req .items > span {
  color: #7e8299;
  font-size: 19px;
  line-height: 27px;
  font-weight: 500;
}
.requsets-box .body-req .items .status {
  font-size: 15px;
  line-height: 32px;
  padding: 0 14px;
  border-radius: 7px;
  width: fit-content;
  font-weight: 700;
}
.requsets-box .body-req .items .status.accept {
  color: #50cd89;
  background: #e8fff3;
}
.requsets-box .body-req .items .status.pending {
  color: #00a3ff;
  background: #f1faff;
}
.requsets-box .body-req .items .status.reject {
  color: #f1416c;
  background: #fff5f8;
}
.requsets-box .body-req .items .status.draft {
  color: #ffb502;
  background: rgba(255, 238, 195, 0.49);
}
.requsets-box .body-req .items .status.cancel {
  color: #f1416c;
  background: #fff5f8;
}
.requsets-box .body-req .items .status.update {
  color: #a216f9;
  background: #f8eeff;
}

.requsets-box .body-req .items .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
}
.review-container {
  margin-top: 50px;
}

.review-container .title {
  font-size: 19px;
  font-weight: bold;
  color: #000;
}
.review-container .caption {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  width: 300px;
}
.review-container .caption.required::after {
  content: "*";
  color: #f53838;
  padding-left: 3px;
}
.review-container > ul {
  display: flex;
  flex-direction: column;
}

.review-container > ul > li .value {
  color: #474747;
  font-size: 16px;
  font-weight: 400;
}
.review-container .group-btn {
  display: flex;
  align-items: center;
  gap: 30px;
  border-top: 1px solid #e0e1e2;
  padding-top: 30px;
}
.review-container .files {
  width: 100%;
  min-height: 85px;
  border: 2px dashed #005dda;
  border-radius: 12px;
}

/*responsive*/
@media only screen and (min-width: 0px) and (max-width: 479px) {
  .panel-profile {
    width: auto;
    padding: 0 5px 50px 5px;
  }
  .panel-profile .list-items a {
    font-size: 15px;
    display: none;
  }
  .panel-profile .list-items li {
    justify-content: center;
    padding: 5px;
  }
  .panel-profile .list-items .userAdi {
    font-size: 12px;
  }
  .panel-profile > .imgLogo {
    display: block;
    width: 30px;
  }
  .panel-profile .logo {
    display: none;
  }
  .requset-container .head-container .head-name {
    gap: 10px;
    font-size: 20px;
  }
  .requset-container .requsets-box .head-req > span {
    font-size: 14px;
  }
  .insidePage .loading-form li {
    width: 40px;
    height: 40px;
  }
  .panel-profile .button {
    width: 45px;
    font-size: 13px;
    text-indent: -1000em;
    height: 40px;
  }

  .panel-profile .button img {
    display: block;
  }

  .insidePage {
    width: calc(100% - 92px);
    margin-left: 90px;
    padding-bottom: 70px;
    padding-right: 20px;
  }

  .insidePage .form {
    row-gap: 20px;
  }
  .insidePage .wrapper .formControl > label {
    font-size: 15px;
    line-height: 28px;
    min-width: unset;
    width: 100%;
  }
  .insidePage .form .formControl {
    flex-wrap: wrap;
    gap: 10px;
  }
  .insidePage .form .formControl textarea {
    height: 100px !important;
  }
  .insidePage .form .formControl > div > label {
    font-size: 14px;
  }
  .businessModel-QA > label {
    font-size: 14px !important;
  }
  .insidePage .form .formControl .txt {
    width: 100%;
    height: 45px;
    font-size: 14px;
    padding: 0 13px;
  }
  .insidePage .form .formControl .txt.txt3 {
    width: 100%;
  }
  .insidePage .btn {
    font-size: 14px;
    width: 100%;
  }
  .insidePage .wrapper {
    width: 100%;
    margin: 0;
  }
  .insidePage .wrapper > h1 {
    font-size: 19px;
  }
  .insidePage .loading-form {
    margin-bottom: 40px;
  }
  .insidePage .wrapper .form label.label3 {
    width: 100%;
    min-width: unset;
  }

  .insidePage .form .formControl .txt.txt5 {
    width: 100%;
  }
  .insidePage .form .formControl .txt.txt2 {
    width: 84%;
  }
  .review-container .info-review {
    padding: 0;
  }
  .review-container input[type="checkbox"]:checked ~ .info-review {
    padding: 20px 0;
  }
  .form .formControl .box,
  .form .formControl .box2 {
    width: 100%;
  }
  .req-box {
    width: 100%;
    flex-wrap: wrap;
  }
  .box2 .businessModel-QA {
    width: 100% !important;
  }
  .form .formControl .box span {
    padding-left: 0;
    font-size: 12px;
  }
  .insidePage .form .formControl.wrap > div {
    gap: 10px;
    flex-wrap: wrap;
  }
  .NewIntroduce-btn {
    width: 32px;
    height: 32px;
    font-size: 25px;
    line-height: 32px;
  }
  .review-container .title {
    font-size: 16px;
  }
  .review-container > ul > li::before {
    display: none;
  }
  .review-container .caption {
    font-size: 17px;
    width: auto;
    min-width: 100%;
    max-width: unset;
  }
  .review-container > ul > li {
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 13px;
  }
  .review-container .info-review > div {
    flex-wrap: wrap;
    gap: 25px;
  }
  .review-container > ul > li .value,
  .review-container .teams {
    width: 100%;
  }
  .formCta-container {
    padding-left: 0;
    padding-right: 25px;
  }
  .form .formControl .box span {
    line-height: 27px;
    margin-top: 5px;
  }
  .insidePage .form .formControl.wrap > div .txt {
    font-size: 13px;
    width: 100%;
  }
  .formCta-next,
  .formCta-back {
    width: 100px;
  }
  .form .tfile {
    height: 115px;
  }
}
/*480 up to 719*/
@media only screen and (min-width: 480px) and (max-width: 719px) {
  .panel-profile {
    width: auto;
    padding: 0 10px 50px 10px;
  }
  .panel-profile .list-items a {
    font-size: 15px;
    display: none;
  }
  .panel-profile .list-items li {
    justify-content: center;
  }
  .panel-profile .logo {
    display: none;
  }
  .panel-profile > .imgLogo {
    display: block;
    width: 30px;
  }
  .panel-profile .button {
    width: 65px;
    font-size: 13px;
    text-indent: -1000em;
  }

  .panel-profile .button img {
    display: block;
  }
  .insidePage {
    width: calc(100% - 107px);
    margin-left: 107px;
    padding-bottom: 70px;
    padding-right: 20px;
  }

  .insidePage .form {
    row-gap: 20px;
  }
  .insidePage .wrapper .form label {
    font-size: 17px;
    line-height: 28px;
    min-width: unset;
    width: 100%;
  }
  .insidePage .form .formControl {
    flex-wrap: wrap;
    gap: 10px;
  }
  .insidePage .form .formControl .txt {
    width: 100%;
    font-size: 16px;
    padding: 0 13px;
  }
  .insidePage .form .formControl .txt.txt3 {
    width: 174px;
  }
  .insidePage .btn {
    font-size: 14px;
    width: 123px;
  }

  .insidePage .form .formControl .txt.txt5 {
    width: 225px;
  }
  .insidePage .form .formControl .txt.txt2 {
    width: 88%;
  }
  .insidePage .wrapper {
    width: 100%;
    margin: 0;
  }
  .insidePage .wrapper .form label.label2 {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
  .form .formControl .box,
  .form .formControl .box2 {
    width: 100%;
  }
  .box2 .businessModel-QA {
    width: 100% !important;
  }
  .insidePage .form .formControl.wrap > div {
    flex-wrap: wrap;
  }
  .insidePage .form .formControl.wrap > div .txt {
    width: 100%;
  }
  .insidePage .form .formControl.wrap > div {
    gap: 10px;
  }
  .NewIntroduce-btn {
    width: 32px;
    height: 32px;
    font-size: 25px;
    line-height: 32px;
  }
  .review-container .caption {
    font-size: 17px;
    width: auto;
    min-width: 100px;
    max-width: unset;
  }
  .review-container > ul > li {
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 13px;
  }
  .formCta-container {
    padding-left: 0;
    padding-right: 25px;
  }
  .form .formControl .box span {
    line-height: 27px;
  }
  .insidePage .form .formControl.wrap > div .txt {
    font-size: 15px;
  }
  .review-container > ul > li::before {
    width: 91%;
  }
  .req-box {
    flex-wrap: wrap;
    width: 100%;
  }
  .req-box label {
    width: fit-content;
    min-width: fit-content !important;
  }
}
/*720 up to 1000*/
@media only screen and (min-width: 720px) and (max-width: 1000px) {
  .panel-profile {
    width: 220px;
    padding: 0 15px 70px 15px;
  }
  .panel-profile .list-items li {
    column-gap: 10px;
  }
  .panel-profile .list-items a {
    font-size: 14px;
  }
  .insidePage {
    width: calc(100% - 250px);
    margin-left: 250px;
    padding-bottom: 70px;
    padding-right: 30px;
  }
  .insidePage .wrapper {
    width: 100%;
    margin: 0;
  }
  .insidePage .wrapper .form label {
    font-size: 17px;
    line-height: 28px;
    min-width: 100%;
  }
  .insidePage .form .formControl {
    flex-wrap: wrap;
    gap: 10px;
  }
  .insidePage .form .formControl .txt {
    width: 100%;
    font-size: 16px;
    padding: 0 13px;
  }
  .insidePage .form .formControl .txt.txt3 {
    width: 48.8%;
  }
  .insidePage .btn {
    font-size: 14px;
    width: 125px;
  }
  .form .formControl .box {
    width: 100%;
  }
  .req-box {
    flex-wrap: wrap;
    width: 100%;
  }
  .req-box label {
    width: fit-content;
    min-width: fit-content !important;
  }
  .form .formControl .box2 {
    width: 100%;
  }
  .insidePage .form .formControl .txt.txt5 {
    width: 306px;
  }
  .insidePage .form .formControl .txt.txt2 {
    width: 90%;
  }
  .insidePage .wrapper .form label.label2 {
    width: 100%;
    min-width: unset;
  }
  .insidePage .form .formControl.wrap > div {
    gap: 10px;
  }
  .NewIntroduce-btn {
    width: 32px;
    height: 32px;
    font-size: 25px;
    line-height: 32px;
  }
  .review-container > ul > li::before {
    width: 91%;
  }
  .review-container .caption {
    font-size: 17px;
    width: 100%;
    min-width: 100%;
    max-width: unset;
  }
  .review-container .teams {
    width: 100%;
  }
  .review-container > ul > li {
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 13px;
  }
  .review-container .info-review > div {
    flex-wrap: wrap;
    gap: 20px;
  }
}
/*1001 up to 1249*/
@media only screen and (min-width: 1001px) and (max-width: 1249px) {
  .insidePage .wrapper {
    width: 100%;
  }
  .insidePage .form .formControl .txt.txt3 {
    width: 192px;
  }
}

@media only screen and (max-width: 700px) {
  .panel-profile .list-items {
    gap: 15px;
  }
}
@media only screen and (max-width: 400px) {
  .panel-profile .list-items {
    gap: 20px;
  }
}
