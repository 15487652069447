@import "_reset";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Inter:wght@400;500;600&family=Poppins:wght@400;500;700;900&display=swap");

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  width: 100%;
  border-bottom: 1px solid #494949;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 35px 40px;
}
header .logo {
  width: 198px;
  height: auto;
}
header .button {
  width: 165px;
  height: 50px;
  background: #1e6424;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
header .button:hover {
  background: #22852a;
}
header .logo {
  width: 201px;
  height: 52px;
}

header .menu {
  border-left: 1px solid #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 30px;
  flex-grow: 1;
}
header .menu a {
  font-size: 15px;
  color: #f0f0f0;
  font-weight: 500;
  line-height: 28px;
  position: relative;
  font-family: "Inter";
  transition: all 0.5s ease;
}
header .menu a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  width: 0px;
  height: 1px;
  background: #66c16d;
  transition: width 0.5s ease;
}
header .menu a:hover {
  color: #66c16d;
}
header .menu a:hover::before {
  width: 100%;
}
footer {
  background: #000800;
  padding-top: 120px;
}
footer .part1 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  column-gap: 110px;
}
footer .about-footer {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
footer .about-footer > p {
  color: #f0f0f0;
  font-weight: 400;
  font-size: 14px;
  line-height: 26.39px;
}
footer .logo-footer {
  width: 212px;
  height: 47px;
}
footer .footer-social {
  display: flex;
  align-items: center;
  gap: 12px;
}
footer .footer-social img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
}
footer .copyRight {
  width: 100%;
  text-align: center;
  margin-top: 70px;
  border-top: 1px solid #f4f4f4;
  padding: 20px 0;
}
footer .copyRight span {
  color: #a4a4a4;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  text-align: center;
}
footer .menu-footer {
  height: 150px;
  display: flex;
  column-gap: 110px;
  row-gap: 10px;
  flex-direction: column;
  width: 300px;
}
footer .menu-footer a {
  color: #f0f0f0;
  line-height: 28px;
  font-size: 14px;
  font-weight: 500;
  font-family: "DM Sans";
  transition: all 0.5s ease;
}
footer .menu-footer a:hover {
  color: #66c16d;
}
footer .newletter-footer {
  width: 438px;
}
footer .newletter-footer > p {
  color: #f0f0f0;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 400;
  font-family: "DM Sans";
  margin-bottom: 46px;
}
footer .form-newsletter {
  padding: 6px 6px 6px 25px;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
footer .form-newsletter .txt {
  color: #959595;
  font-size: 13px;
  font-family: "DM Sans";
  width: calc(100% - 165px);
}
footer .form-newsletter .txt::placeholder {
  color: #959595;
  opacity: 1;
}
footer .form-newsletter .btn {
  width: 154.79px;
  height: 46px;
  border-radius: 6px;
  background: #1e6424;
  transition: all 0.5s ease;
  color: #fff;
  font-size: 13px;
  font-family: "DM Sans";
  font-weight: 400;
}

.action-btns {
  display: flex;
  gap: 5px;
}

.action-btns button {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;

  width: 225px;
  height: 54px;
  top: 4180px;
  left: 626px;
  border-radius: 12px;
  border: 1px;
  border: 1px solid #d7d7d7;
}
.accept-btn {
  background-image: linear-gradient(
    to right,
    rgba(115, 217, 130, 1) 0%,
    rgba(6, 108, 21, 1) 100%
  );
  color: #fff;
}
.back-btn {
  background: #232323;
  color: #fff;
  transform: scale(0.8);
}

.reject-btn {
  background-color: #f53838;
  color: #fff;
}
.update-btn {
  background: #e4e3e3;
  color: #888888;
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  header {
    padding: 12px 20px;
    justify-content: space-between;
    position: static;
    background: #000800;
  }
  header .button {
    width: auto;
    height: auto;
    padding: 10px 15px;
  }
  header .menu {
    display: none;
  }
  header .logo {
    width: 180px;
    height: auto;
  }
  footer {
    padding-top: 35px;
  }
  footer .about-footer {
    width: 100%;
    gap: 10px;
  }
  footer .part1 {
    column-gap: 28px;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  footer .menu-footer {
    column-gap: 40px;
    width: 100%;
    height: 80px;
  }
  footer .newletter-footer > p {
    margin-bottom: 25px;
  }
  footer .copyRight {
    margin-top: 35px;
  }
  footer .copyRight {
    padding: 12px 0;
  }
  footer .copyRight span {
    font-size: 13px;
  }
  footer .form-newsletter .btn {
    width: 110px;
  }
  footer .form-newsletter .txt {
    width: calc(100% - 120px);
  }
  footer .form-newsletter {
    padding: 6px 6px 6px 12px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 719px) {
  header {
    padding: 12px 20px;
    justify-content: space-between;
  }
  header .button {
    width: auto;
    height: auto;
    padding: 10px 15px;
  }
  header .menu {
    display: none;
  }
  footer {
    padding-top: 35px;
  }
  footer .about-footer {
    width: 100%;
    gap: 10px;
  }
  footer .part1 {
    column-gap: 28px;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  footer .menu-footer {
    column-gap: 40px;
    width: 100%;
    height: 80px;
  }
  footer .newletter-footer > p {
    margin-bottom: 25px;
  }
  footer .copyRight {
    margin-top: 35px;
  }
  footer .copyRight {
    padding: 12px 0;
  }
  footer .copyRight span {
    font-size: 13px;
  }
  footer .form-newsletter .btn {
    width: 110px;
  }
  footer .form-newsletter .txt {
    width: calc(100% - 120px);
  }
  footer .form-newsletter {
    padding: 6px 6px 6px 12px;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1000px) {
  header {
    padding: 15px 20px;
  }
  header .button {
    width: auto;
    height: auto;
    padding: 10px 15px;
  }
  footer {
    padding-top: 40px;
  }
  footer .part1 {
    column-gap: 28px;
  }
  footer .menu-footer {
    column-gap: 40px;
  }
  footer .copyRight {
    margin-top: 55px;
  }
  footer .copyRight {
    padding: 15px 0;
  }
  footer .form-newsletter .btn {
    width: 110px;
  }
  footer .form-newsletter .txt {
    width: calc(100% - 120px);
  }
  footer .form-newsletter {
    padding: 6px 6px 6px 12px;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1249px) {
  header {
    padding: 15px 30px;
  }
  header .button {
    height: 45px;
  }
  footer {
    padding-top: 55px;
  }
  footer .part1 {
    column-gap: 50px;
  }
  footer .menu-footer {
    column-gap: 70px;
  }
  footer .copyRight {
    margin-top: 55px;
  }
  footer .copyRight {
    padding: 15px 0;
  }
  footer .form-newsletter .btn {
    width: 110px;
  }
  footer .form-newsletter .txt {
    width: calc(100% - 120px);
  }
}

.head-container {
  position: relative;
}
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.pagination-container button,
.pagination-container p {
  color: rgba(6, 108, 21, 1);
  font-size: 18px;
  border: 1px solid #066c15;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  /* right: 0; */
}
