.userNftContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background: #ffffff5b;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.userNftContent {
  width: 500px;
  height: 407px;
  border-radius: 20px;
  border: 1px solid #727272;
  padding: 20px;
  background: #ffff;
}
.headerAssetModal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerAssetModal > button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
}
.headerAssetModal > h4 {
  font-weight: 700;
  font-size: 18px;
  color: #575757;
}
.NftOptions {
  max-height: 250px;
  width: 100%;
  background: none;
  z-index: 20;
  display: flex;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-wrap: wrap;
  margin-top: 5px;
}
.NftOptions::-webkit-scrollbar {
  width: 5px;
}
.NftOptions::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Customize the appearance of the scrollbar thumb */
.NftOptions::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}
.NftOptions > button {
  width: 100px;
  background: none;
  min-height: 102px;
  color: #fdfdff;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: background 0.5s;
  border-radius: 4px;
  border: 1px solid rgba(71, 71, 75, 1);
  margin: 5px;
  padding: 0;
}
.titleNfts {
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  margin: 0;
  background: rgb(173, 173, 173);
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden;
}
.NftOptions > button:hover {
  background: rgba(71, 71, 75, 1);
  border-radius: 4px;
}
.NftsContainer-img {
  width: 100%;
  height: 70px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.NftsContainer-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.sereachContainer {
  width: 100%;
  height: auto;
  border: none;
  border-bottom: 1px solid #b8b8b8;
  padding-top: 20px;
  padding-bottom: 20px;
}
.sereachContainer > input {
  border: 1px solid #c9c9c9 !important;
  color: #000000 !important;
  outline: none !important;
  border-radius: 4px !important;
  background: url("../../assets/icons/SearchIcon.svg") 12px center / 20px 20px
    no-repeat scroll rgb(255, 255, 255);
  width: 100%;
  position: relative;
  display: flex;
  padding: 16px 16px 16px 40px;
  height: 40px;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  outline: none;
  border-radius: 12px;
  color: rgb(255, 255, 255);
  border: 1px solid rgba(152, 161, 192, 0.24);
  appearance: none;
  font-size: 16px;
  transition: border 100ms ease 0s;
}
