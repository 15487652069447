.transfer-info-container {
  display: none;
  position: fixed;
  z-index: 25;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}
.transfer {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: linear-gradient(to right, #66c16d 0%, #22852a 100%);
  position: absolute;
  top: 45px;
  right: 45px;
}

.transfer span {
  font-size: 14px;
  color: white;
  font-weight: 700;
}

.transfer-info {
  width: 378px;
  /* height: 347px; */
  position: relative;
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 20px;
  gap: 15px;
  animation: slide-in 0.3s ease;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.transfer-header {
  border-bottom: #eaeaea 2px solid;
  border-radius: 4px;
  width: 100%;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  padding-bottom: 5px;
}

.transfer-to {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #67b16d;
  border-radius: 8px;
}
.transfer-to span {
  color: #67b16d;
}
.transfer-to input {
  width: 100%;
}

.transfer-asset {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  /* justify-content: space-between; */
}
.transfer-asset-amount {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: #bebebe 1px solid;
  border-radius: 8px;
  padding: 5px 15px;
  flex-grow: 1;
  position: relative;
}

.transfer-asset-amount .gasfee {
  position: absolute;
  font-size: 10px;
  bottom: -13px;
  width: 100%;
  right: 0;
  text-align: right;
}

.transfer-asset-amount .grey {
  color: #7e8299;
  font-weight: 600;
}
.transfer-asset span {
  position: relative;
  display: grid;
  place-items: center;
  width: calc(25% - 15px);
}
.transfer-asset span button {
  position: absolute;
  bottom: -70%;
  left: 50%;
  transform: translateX(-50%);
  background: #22852a;
  color: #fff;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 10px;
}
.transfer-asset-amount img {
  border-left: 2px solid #bebebe;
  padding-left: 5px;
  width: 30px;
  height: 30px;
}
/* .transfer-asset-amount .amount {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
} */
.transfer-asset-amount input[type="number"] {
  color: #696c80;
  font-weight: 600;
  font-size: 16px;
}
.transfer-asset-amount input[type="number"]::placeholder {
  color: #bebebe;
}
.transfer-asset-amount input[type="number"]::-webkit-inner-spin-button,
.transfer-asset-amount input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.transfer-asset-amount .amount p {
  position: absolute;
}
.transfer-asset-amount .amount,
.transfer-asset-amount .refresh {
  flex: 1;
}

.transfer-btn {
  width: 100%;
  height: 50px;
  top: 291px;
  left: 15px;
  padding: 15px, 24px, 10px, 24px;
  border-radius: 8px;
  background: linear-gradient(to right, #66c16d 0%, #22852a 100%);
  color: #fff;
  font-weight: 700;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 600px) {
  .transfer-info {
    /* max-width: 90%; */
  }
  .transfer {
    /* width: fit-content; */
    padding: 5px 10px;
  }
  .transfer .transfer-icon {
    width: 30px;
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.show {
  display: block;
  opacity: 1;
}

@keyframes slide-in {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.transfer-address {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.transfer-message {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 80%;
  font-weight: 100;
}
.transfer-message.success p {
  color: #22852a;
}
.transfer-message.success a {
  text-decoration: underline;
}
.transfer-message.error p {
  color: #c50000;
}
