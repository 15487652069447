.application-sections {
  width: 70%;
  padding: 25px;
  /* margin: 0 auto; */
}
.application-sections .section-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  border-bottom: 2px solid #dddddd;
}
/* .application-sections .section-item { */

.application-sections .section-item:nth-child(1) {
  border-top: 2px solid #dddddd;
}
.section-item .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  color: #7e8299;
}

.section-item button {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: linear-gradient(to right, #16881f 0%, #034d09 100%);
  color: #fff;
  font-weight: 700;
  padding: 5px 10px;
}

.submit-application {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-application button {
  border-radius: 6px;
  background-color: #232323;
  color: #fff;
  padding: 5px 15px;
  font-size: 1.1rem;
}
.form-application {
  width: 90%;
  padding-top: 35px;
  padding-left: 35px;
  /* min-height: calc(100vh + 100px); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 35px;
}
.form-application-header {
  font-size: 17px;
  font-weight: 700;
  width: 100%;
  position: relative;
}

.application-header {
  font-size: x-large;
  position: relative;
  padding: 35px;
  padding-bottom: 0;
}
.application-header::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -10%;
  width: calc(100%);
  background: linear-gradient(
    to right,
    #c9cacb10 0%,
    #b3b3b352 50%,
    #c9cacb24 100%
  );
  height: 1px;
}
/* .form-application-header::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: calc(100% - 150px);
  background-color: #c9cacb;
  height: 1px;
} */

.form-application .form-controller {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 10fr;
  /* grid-template-rows: 100px; */
  min-height: 100px;
  column-gap: 5px;
  position: relative;
}

.form-controller.upload {
  grid-template-columns: 10fr 3fr;
  grid-template-rows: 35px;
  min-height: 0;
}
.upload-label {
  background-color: #034d09;
  color: white !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.upload-label input#uplaod {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  appearance: none;
  background: transparent;
  padding: 50px;
  border: none;
}

.form-controller label {
  font-weight: 400;
  font-size: 14.49px;
  color: #000;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.form-controller input,
.form-controller select {
  align-self: baseline;
}
.form-controller input {
  border: 0.5px solid #a4a4a4c2;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 5px;
}
.form-controller.usd::after {
  content: "USD";
  width: 50px;
  /* height: 40%; */
  color: #8f8f8f;
  padding: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 20%;
  z-index: 5;
}
.form-controller.shortinput {
  min-height: 0;
  grid-template-rows: unset;
}
.form-controller.shortinput label {
  place-items: center;
  margin-bottom: 5px;
}
.form-controller input:disabled {
  background-color: #d0d0d0;
}
.form-controller textarea {
  border: 0.5px solid #a4a4a4c2;
  background-color: #fff;
  border-radius: 8px;
  resize: vertical;
  padding: 10px 5px;
  font-family: "Roboto";
  line-height: 1.5;
}

.form-controller .range {
  width: 50%;
  margin: 0 auto;
  position: relative;
}

.form-controller .trl-select {
  border: 0.5px solid #a4a4a4c2;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  padding: 10px 5px;
}

.form-controller .select-controller {
  display: grid;
  /* justify-content: ; */
  column-gap: 10px;
  grid-template-columns: 2fr 8fr;
}
.select-controller select {
  background-color: #fff;
  border: 0.5px solid #a4a4a4c2;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 5px;
}

.range span {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  bottom: -15px;
}
.range span p {
  display: flex;
  align-items: center;
  gap: 10px;
  /* bottom: -15px; */
}
.range .rangeTootltip {
  display: none;
  color: #5a9975;
  border: 1px solid #017e29;
  background: #e7ffec;
  padding: 10px 15px;
  width: fit-content;
  position: absolute;
}
.range .rangeTootltip p {
  display: none;
}

.range span:nth-of-type(1) {
  left: 0;
}
.range span:nth-of-type(2) {
  left: 50%;
  transform: translateX(-50%);
}
.range span:nth-of-type(3) {
  right: 0;
}
.form-controller .range input[type="range"] {
  /* appearance: none; */
  accent-color: green;
  width: 100%;
}

.form-table {
  width: 100%;
  min-height: 150px;
}
.form-table-items {
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(1fr); */
  grid-template-columns: repeat(3, 3fr) 1fr;
  height: 50px;
  padding: 5px 10px;
  padding-left: 15px;
  border-bottom: #dddddd 1px solid;
}
.form-table-items:nth-of-type(2) {
  background-color: #bebebe49;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: #3f4254;
  font-size: 13px;
  font-weight: 500;
  border-bottom: #dddddd 1px solid;
}

.form-application .form-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.table-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}
.table-item input {
  background: #ffffff;
  border: #dedede 1px solid;
  border-radius: 4px;
  width: 75%;
  padding: 5px 10px;
}

.letsgo-btn {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: linear-gradient(to right, #16881f 0%, #034d09 100%);
  color: #fff;
  font-weight: 700;
  padding: 5px 15px;
  font-size: 1.1rem;
}

.formCta-back {
  background-color: #232323;
  color: #fff;
}
.formCta-back:hover {
  background: #535353;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

span.red {
  color: #ff0000;
}
.upload_view {
  border: #034c09 1px solid;
  color: #034c09;
  border-radius: 3px;
  width: fit-content;
  padding: 5px 10px;
  font-size: 12px;
  margin-left: 5px;
}

.mobile-table-item {
  display: none;
}
@media only screen and (max-width: 1000px) {
  .form-application {
    gap: 15px;
  }
  /*Small smartphones [325px -> 425px]*/
  .form-application .form-controller {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
  }
  .form-application .form-controller.upload .upload-label {
    width: 50%;
    height: 40px;
    justify-self: center;
    margin-top: 15px;
  }
  .form-application .form-controller {
    margin-bottom: 5px;
  }
  .form-controller.usd::after {
    top: 50%;
  }
  .application-sections {
    width: auto;
  }
}

@media only screen and (max-width: 500px) {
  .mobile-table-item {
    display: block;
  }
  .form-table-items:nth-of-type(2) {
    display: none;
  }
  .form-table-items:nth-of-type(3) {
    background-color: #bebebe49;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: #3f4254;
    font-size: 13px;
    font-weight: 500;
    border-bottom: #dddddd 1px solid;
    height: 100px;
  }
  .form-table-items {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    height: 150px;
    background-color: #fff;
  }
  .table-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-item input[type="radio"] {
    width: unset;
  }
  .application-sections {
    padding: 25px 0;
  }
  .form-application {
    padding-left: 0;
  }

  .table-item {
    font-size: 0.8rem;
  }
}
