.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0.9;
  z-index: 999;
  border: 1px solid #582d0e;
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 15px;
}

.toast > img {
  width: 25px;
  height: 25px;
}
.toast span {
  text-transform: capitalize;
}
.toast.error {
  background-color: #ffe5e9;
  color: #640102;
  border-color: #640102;
}
span.error {
  color: #640102;
  font-weight: 700;
}
.toast.success {
  background-color: #e7ffec;
  color: #017e29;
  border-color: #017e29;
}
span.success {
  color: #017e29;
  font-weight: 700;
}
.toast.warning {
  background-color: #fff5e7;
  color: #582d0e;
  border-color: #582d0e;
}
span.warning {
  color: #672900;
  font-weight: 700;
}
.toast.info {
  background-color: #e6f4ff;
  color: #012a66;
  border-color: #012a66;
}
span.info {
  color: #012a66;
  font-weight: 700;
}

.toast-content {
  text-align: center;
}
