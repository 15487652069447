.application-container.requset-container {
  background: none;
  border: none;
  border-radius: 0;
  margin-right: 0;
  margin-left: 275px;
  /* min-height: 140vh; */
  /* padding-bottom: 80px; */
}

.application-container h2 {
  font-weight: 600;
  color: #3f4254;
  font-size: 22px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.application-banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.application-banner h1 {
  color: #fff;

  font-weight: 600;
  line-height: 30px;
  font-size: 40px;
}
.application-content {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 2fr;
  min-height: 500px;
}

.application-content .application-list {
  /* display: grid; */
  position: relative;
  padding: 15px;
  /* width: 65%; */
  height: 300px;
}

.application-item:nth-child(1) {
  background: rgba(232, 233, 233, 1);
  color: black;
  font-weight: 700;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.application-item button {
  /* background: #c7ffcb; */
  /* color: #5c5c5c; */
  background: #22842a;
  color: #fff;
  padding: 5px 12px;
  font-size: 12px;
  border-radius: 7px;
}

.application-item:nth-child(1)::before {
  background-color: transparent;
}
.application-item::before {
  background-color: #00a3ff;
}
.application-item.SFS_UPDATE::before {
  background-color: #a216f9;
}
.application-item.SFS_DRAFT::before {
  background-color: #ea9f30;
}
.application-item.SFS_REJECTED::before {
  background-color: #f65050;
}
.application-item.SFS_ACCEPTED::before {
  background-color: #66c16d;
}
.application-item::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
}
.application-item {
  position: relative;
  border-bottom: solid 1px #d9d9d9;
  color: #5c5c5c;
  display: grid;
  grid-template-columns: repeat(2, 2fr) repeat(2, 1fr);
  gap: 10px;
  place-items: center;
  height: 40px;
  font-size: 14px;
}

.application-item p {
  text-align: left;
  width: 90%;
}
.application-item > * {
  text-align: center;
  /* grid-template-columns: 1fr 1fr 1fr 1f; */
}

.application-content .application-statistic,
.application-content .submit-challenge {
  /* width: calc(35% - 15px); */
  min-height: 300px;
  background-color: rgba(232, 233, 233, 1);
  align-self: flex-start;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 15px 10px;
  border-radius: 8px;
  margin-right: 15px;
}

.application-content .submit-challenge {
  background-color: transparent;
  padding: 5px;
  flex-wrap: nowrap;
  gap: 10px;
  /* border: #bbbbbb 1px solid; */
  position: relative;
  overflow: hidden;
  padding-top: 15px;
}
/* .application-content .submit-challenge::after {
  content: "Challenge";
  color: #08415a;
  display: flex;
  font-weight: 700;
  padding-top: 20px;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: 20px;
  width: 100px;
  height: 70px;
  background-color: #ffb800;
  clip-path: polygon(0 0, 48% 0, 100% 0, 100% 100%, 49% 68%, 0 100%);
  transform: scale(0.9);
} */

.application-content .submit-challenge img {
  transform: scale(1.1);
}
.application-content .submit-challenge button {
  width: 100%;
  padding: 10px;
  color: #22852a;
  border: #22852a 1px solid;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
}
.application-statistic > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-bottom: #23232314 1px solid;
}
.application-modal-container {
  display: flex;
  /* place-items: center; */
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 25;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
  overflow: hidden;
}
.application-modal {
  width: 370px;
  min-height: 450px;
  position: relative;
  background-color: #fefefe;
  /* margin: 10% auto; */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 20px;
  max-width: 35%;
  animation: slide-in 0.3s ease;
  display: flex;
  flex-direction: column;
}
.application-modal .application-header {
  text-align: center;
  /* border-bottom: 1px solid #c3c3c3; */
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
}
.application-modal .modal-close {
  z-index: 15;
  position: absolute;
  top: 22px;
  right: 15px;
}
.application-modal .modal-item {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c3c3c3;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}
.auditor-message::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}
.auditor-message::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.auditor-message::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4d9c41),
    to(#19911d),
    color-stop(0.6, #54de5d)
  );
}
.auditor-message {
  margin: 10px 0;
  border-bottom: 1px solid #c3c3c3;
  height: 100px;
  overflow-y: scroll;
}

.auditor-message .message {
  padding-top: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
}
.modal-item .status {
  padding: 3px 15px;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  font-size: 10px;
}
.modal-item .status.accepted {
  background-color: #66c16d;
}
.modal-item .status.rejected {
  background-color: #f65050;
}
.modal-item .status.draft {
  background-color: #ea9f30;
}
.modal-item .status.pending {
  background-color: #00a3ff;
}
.modal-item .status.update {
  background-color: #a216f9;
}
.modal-item .status.any {
  background-color: #ea9f30;
}

.action-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.action-container .actions {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}
.action-container .actions > div {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.ET_FUM_BLOCKCHAIN_EVENT_2024 {
  background-color: #ffb800 !important;
  color: #000000 !important;
  /* position: absolute;
  right: -120%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 90%;
}
.ET_FUM_BLOCKCHAIN_EVENT_2024 span {
  font-size: 70%;
}
.application-pagination {
  margin-top: 15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  width: 100%;
}
.application-pagination button {
  margin: 0 10px;
}
.action-container .actions span {
  font-size: 10px;
}

div.disable {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-preview img {
}
.btn-preview {
  position: relative;
  background-image: linear-gradient(
    to right,
    /* rgba(115, 217, 130, 1) 0%, */ rgb(7, 121, 24) 0%,
    rgb(7, 121, 24) 100%
  );
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  background-size: 100% auto;
}
@keyframes slide-in {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 1100px) {
  /*Tablets [601px -> 1200px]*/
  .application-banner h1 {
    font-size: 20px;
  }
  .application-content .application-statistic {
    font-size: 80%;
  }
  .application-statistic > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .application-modal {
    max-width: none;
    width: 40%;
  }
}

@media only screen and (max-width: 850px) {
  .application-content {
    /* display: grid; */
    /* flex-direction: column; */
    grid-template-columns: 1fr;
    gap: 15px;
  }
  .application-content .submit-challenge {
    order: 1;
  }
  .application-content .application-list {
    padding: 0;
  }
  .application-content .application-list,
  .application-content .application-statistic {
    width: 100%;
    order: 2;
  }
}

@media only screen and (max-width: 700px) {
  .application-modal {
    max-width: none;
    width: 60%;
  }
  .application-container > h2 {
    margin-left: 0;
    transform: translateY(10px);
    font-size: 90%;
  }
}
@media only screen and (max-width: 550px) {
  .application-item {
    grid-template-columns: repeat(3, 1fr);
  }
  .application-item p:nth-child(2) {
    display: none;
  }
  .application-banner {
    padding: 10px;
  }
  .application-banner h1 {
    font-size: 70%;
  }
}

@media only screen and (max-width: 550px) {
  .application-modal {
    max-width: none;
    width: 90%;
  }
}

@media only screen and (max-width: 550px) {
  .application-item > * {
    font-size: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .application-item > * {
    font-size: 80%;
  }
}
