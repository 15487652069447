/* auth */

.auth {
  background-color: #f8f9fa;
  padding: 0;
  margin: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
}
.auth.register {
  gap: 0px;
}
.auth-header img {
  width: 350px;
}

.auth .auth-form {
  /* flex: 0.5; */
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 15px;
}
form h1 {
  width: 100%;
  text-align: left;
  color: var(--color-black-primary);
}
.auth form {
  width: 47%;
  /* height: 760px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.auth.register form {
  gap: 15px;
}

.auth .error {
  color: red;
  font-size: 10px;
}

.input-controller-auth {
  display: flex;
  justify-content: baseline;
  gap: 5px;
  flex-direction: column;
  width: 100%;
  /* position: relative; */
}
.input-controller-auth .input-icon {
  position: relative;
  width: 100%;
}
.input-controller-auth label {
  padding-left: 13px;
  color: #333;
}
.input-controller-auth input {
  border: none;
  background-color: #fff;
  padding: 15px 15px;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  /* color: (var(--color-black-primary)); */
  color: #000;
  /* color: (var(--color-grey-light)); */
  font-size: 0.9rem;
  font-weight: 300;
  width: 100%;
}
.input-controller-auth input:focus {
  outline: none;
}
.input-controller-auth input::placeholder {
  color: #b3b3b3;
}
.input-controller-auth img {
  position: absolute;
  width: 18px;
  height: 16px;
  top: 35%;
  left: 15px;
  transform: translate(-50%, -10%);
}
.input-controller-auth label {
  color: var(--color-black-primary);
}

.auth-form .btn {
  width: 100%;
  font-size: 1.2rem;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-form a {
  color: var(--color-font-secondary);
}
.auth-form a.btn {
  width: 100%;
  text-align: center;
  /* font-size: 1.1rem; */
}
.auth-form .divider {
  width: 100%;
  position: relative;
  color: var(--color-grey-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.auth-form .divider::after,
.auth-form .divider::before {
  position: absolute;
  content: "";
  width: 110px;
  height: 1px;
  background-color: var(--color-grey-light);
}
.auth-form .divider::after {
  right: 25px;
}
.auth-form .divider::before {
  left: 25px;
}
/* auth end */

/* Forgot Pssword */
.confirm-otp-controller {
  width: 100%;
}
.confirm-otp-controller div {
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  /* width: 55px !important; */
  /* padding: 0 0.25rem !important; */
}
.confirm-otp-controller div input {
  /* flex-basis: 50%; */
  font-size: 32px;
  width: 1.5em !important;
  border: none;
  border: #7d7d7d 1px solid;
  border-radius: 5px;
  background-color: #fff;
}

.pendingforactivation {
  /* flex flex-col justify-center items-center  py-3 w-full px-4 gap-6 bg-white relative rounded-md shadow-xl */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  gap: 30px;
  background-color: #fff;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.auth-header-mobile {
  display: none;
}
.heading-l {
  font-size: 20px;
  font-weight: 300;
}
@media only screen and (max-width: 1200px) {
  /*Small smartphones [425px ->1200px]*/
  .showcase {
    display: none;
  }
  .auth {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .auth form {
    width: 100% !important;
  }
  .auth .auth-form {
    flex: 1;
    width: 80%;
  }

  .auth-header-mobile {
    display: flex;
    /* padding-top: 50px; */
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }
  .auth-header-mobile > img {
    transform: translate(-10px, 10px);
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  form > h1 {
    /* display: none; */
  }
  .auth form {
    width: 100% !important;
  }
}

.login-btn {
  background: linear-gradient(to left, #22852a 0, #66c16d 100%);
  width: 100%;
  padding: 10px 15px;
  display: block;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opacity-70 {
  opacity: 70%;
}
