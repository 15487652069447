@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Roboto:wght@400;700&display=swap");

.profile-page {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 55px;
  font-family: "Roboto";
  background: #f8f9fa;
  padding: 45px 0;
  min-height: 100vh;
}
.insidePage {
  width: calc(100% - 278px);
}
.insidePage .wrapper {
  width: 925px;
  margin: 0 auto;
}
.insidePage .wrapper > h1 {
  font-size: 23px;
  color: #000000;
  line-height: 50px;
  margin: 18px 0;
  text-align: center;
  font-weight: 700;
  position: relative;
}
.insidePage .wrapper > h1::before {
  position: absolute;
  content: "";
  width: 200px;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(13, 125, 140, 0.35) 0%,
    rgba(13, 13, 28, 0.68) 49%,
    rgba(13, 125, 140, 0.35) 100%
  );
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0.3;
}
.insidePage .wrapper > img {
  width: 342px;
  margin: 0 auto;
}
.insidePage .form {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.insidePage .form .formControl {
  display: flex;
  gap: 20px;
}
.insidePage .form .formControl.wrap {
  flex-wrap: wrap;
}
.insidePage .form .formControl.wrap > div {
  display: flex;
  gap: 18px;
}
.w-100 {
  width: 100%;
  max-width: 100% !important;
  flex-wrap: wrap !important;
}
.insidePage .form .formControl .txt {
  width: calc(100% - 325px);
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e2e8f0;
  background: #ffffff;
  padding: 0 20px;
  color: #000;
  /* color: #b3b3b3; */
  font-size: 23px;
  font-weight: 400;
  transition: all 0.5s ease;
}
/* .insidePage .form .formControl .txt */
.insidePage .form .formControl textarea {
  height: 150px !important;
  padding-top: 10px !important;
}
.insidePage .form .formControl .txt::placeholder {
  opacity: 1;
}
.insidePage .form .formControl .txt:focus {
  border-color: #f53838;
}
.insidePage .form .formControl .txt.txt2 {
  width: 59%;
}
.insidePage .form .formControl .txt.txt3 {
  width: 31.3%;
}
.insidePage .form .formControl .txt.txt4 {
  width: 280px;
}
.insidePage .form .formControl .txt.txt5 {
  width: 354px;
}

.insidePage .wrapper .formControl > label {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  line-height: 28px !important;
  width: 305px;
}
.insidePage .wrapper .form label.required::after {
  content: "*";
  color: #f53838;
  padding-left: 3px;
}
label.socialmedia-label input {
  transform: scale(1.3);
  accent-color: #22852a;
}
label.socialmedia-label {
  display: flex;
  align-items: center;

  gap: 10px;
}
.socialmedia-label + input:disabled {
  background-color: #d1d2d7 !important;
}

.insidePage .form .formControl > div > label {
  color: #6c6c6c;
  font-size: 23px;
  line-height: 35px;
  font-weight: 400;
  margin-left: 5px;
}
.insidePage .wrapper .form .radio {
  width: 18px;
  height: 18px;
}
.insidePage .btn {
  background-image: linear-gradient(
    to right,
    rgba(115, 217, 130, 1) 0%,
    rgba(6, 108, 21, 1) 100%
  );
  border-radius: 12px;
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  width: 225px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  background-size: 100% auto;
}
.insidePage .btn:hover {
  background-position: right center;
  background-size: 150% auto;
}
.insidePage .btn2 {
  border: 1px solid #d7d7d7;
  color: #888888;
  font-size: 23px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #e4e3e3;
  width: 225px;
  height: 50px;
  transition: all 0.5s ease;
}
.insidePage .btn2:hover {
  background: #c8c8c8;
}
.insidePage .form .formControl .group-btn {
  display: flex;
  align-items: center;
  gap: 25px;
}
.insidePage .button {
  background-color: #f53838;
  border-radius: 12px;
  width: 225px;
  height: 50px;
  font-size: 23px;
  color: #fff;
  font-weight: 700;
  transition: background-color 0.5s;
}
.insidePage .button:hover {
  background-color: #f51212;
}

.insidePage .form .social {
  display: flex;
  align-items: center;
  gap: 15px;
}

.insidePage .form .social img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.insidePage .loading-form {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 60px;
}
.insidePage .loading-form span {
  width: 60px;
  height: 10px;
  border-radius: 8px;
  background: #c7ebe9;
}
.insidePage .loading-form span.fill {
  background: linear-gradient(
    to right,
    rgba(115, 217, 130, 1) 0%,
    rgba(6, 108, 21, 1) 100%
  );
}
.form .formControl .box {
  display: flex;
  flex-direction: column;
}
.form .formControl .box span {
  font-size: 14px;
  line-height: 33px;
  color: #000000;
  padding-left: 20px;
}
.form .formControl > ul {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.form .formControl > ul label {
  color: #6c6c6c !important;
  margin-left: 5px;
}
.form .tfile {
  width: 100%;
  height: 150px;
  border: 2px dashed #005dda;
  border-radius: 12px;
  background: #fff url(./images/upload.png) no-repeat center center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

/*panel profile page*/

.panel-profile {
  position: relative;
  width: 278px;
  min-height: 100vh;
  /* border-right: 2px solid #c5c5c5; */
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
  padding: 0 30px 75px 30px;
  position: fixed;
}
.panel-profile > img {
  width: 152px;
}
.panel-profile .list-items {
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
  flex-grow: 1;
}

.panel-profile .list-items li {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 10px;
  cursor: pointer;
}
.panel-profile .list-items li.active {
  background: #c7ffcb;
  border-radius: 4px;
}

.panel-profile .list-items li.active img {
  filter: unset;
}
.panel-profile .list-items li.active a {
  background-image: -webkit-linear-gradient(45deg, #22852a 100%, #66c16d 0%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.panel-profile .list-items li:hover a {
  color: #22852a;
}
.panel-profile .list-items li img {
  filter: invert(1) grayscale(1);
}
.panel-profile .list-items a {
  color: #5b5a5a !important;
  font-size: 16px;
  transition: all 0.5s ease;
}
.panel-profile .btn-div-logout .user-role {
  position: absolute;
  top: -25px;
  left: 0;
  font-size: 16px;
  font-weight: 600;
  color: #22852a;
}
.panel-profile .button {
  position: relative;
  background-image: linear-gradient(
    to right,
    rgba(115, 217, 130, 1) 0%,
    rgba(6, 108, 21, 1) 100%
  );
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  background-size: 100% auto;
}
.panel-profile .button:hover {
  background-size: 150% auto;
  background-position: right center;
}
.endStep-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 38px;
}
.endStep-form > h4 {
  color: #000;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 62.4px */
  letter-spacing: 1.56px;
}
.endStep-form > p {
  color: #b7b7b7;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 34.8px */
  letter-spacing: -0.29px;
}
.requset-container {
  background: #fff;
  border-radius: 14px;
  width: calc(100% - 278px);
  margin-right: 50px;
  margin-left: 300px;
}
.requset-container .head-container {
  padding: 20px 35px;
}
.requset-container .head-container .head-name {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 24px;
  color: #3f4254;
  font-weight: 600;
}
.requset-container .requsets-box {
  width: 100%;
  padding-bottom: 50px;
}
.requset-container .requsets-box .head-req {
  background: #f5f8fa;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  width: 100%;
  border-bottom: 1px solid #eff2f5;
  border-top: 1px solid #eff2f5;
  padding: 10px 35px;
}
.requset-container .requsets-box .head-req > span {
  color: #3f4254;
  font-size: 19px;
  font-weight: 400;
}
.requset-container .requsets-box .head-req > span:last-child {
  text-align: center;
}

.requset-container .requsets-box .body-req .items {
  padding: 20px 35px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  width: 100%;
  border-bottom: 1px solid #eff2f5;
}
.requsets-box .body-req .items > span {
  color: #7e8299;
  font-size: 19px;
  line-height: 27px;
  font-weight: 500;
}
.requsets-box .body-req .items .status {
  font-size: 15px;
  line-height: 32px;
  padding: 0 14px;
  border-radius: 7px;
  width: fit-content;
  font-weight: 700;
}
.requsets-box .body-req .items .status.accept {
  color: #50cd89;
  background: #e8fff3;
}
.requsets-box .body-req .items .status.pending {
  color: #00a3ff;
  background: #f1faff;
}
.requsets-box .body-req .items .status.reject {
  color: #f1416c;
  background: #fff5f8;
}
.requsets-box .body-req .items .status.draft {
  color: #ffb502;
  background: rgba(255, 238, 195, 0.49);
}
.requsets-box .body-req .items .status.update {
  color: #a216f9;
  background: #f8eeff;
}

.requsets-box .body-req .items .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
}

.review-container .title {
  font-size: 23px;
  font-weight: bold;
  color: #000;
  display: block;
  margin: 0;
  cursor: pointer;
  transition: 0.2s;
  background: #f8f9fa;
  z-index: 1;
  padding-right: 15px;
  hyphens: auto;
}

/**/
.review-container > ul > li {
  position: relative;
  overflow: hidden;
  padding: 10px 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}
.review-container > ul > li::before {
  position: absolute;
  content: "";
  width: 95%;
  height: 1px;
  background: #62bd69;
  top: 22px;
  left: 0;
}
.review-container input[type="checkbox"] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 50px;
  z-index: 1;
  opacity: 0;
  touch-action: manipulation;
  top: 0;
}
.review-container li i {
  position: absolute;
  transform: translate(-6px, 0);
  right: 15px;
  top: 27px;
  transform: rotate(180deg);
}
.review-container li i:before,
.review-container li i:after {
  content: "";
  position: absolute;
  background-color: #22852a;
  width: 3px;
  height: 9px;
}
.review-container li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}
.review-container li i::after {
  transform: translate(2px, 0) rotate(-45deg);
}
.review-container .info-review {
  max-height: 0 !important;
  transition: 0.3s;
  opacity: 0 !important;
  padding: 0;
  width: 100%;
  padding: 30px 0;
  transition: 0.3s opacity, 0.6s max-height;
}
.review-container .info-review > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 50px;
}

.review-container input[type="checkbox"]:checked ~ .info-review {
  position: relative;
  max-height: unset !important;
  will-change: max-height;
  contain: layout;
  display: inline-block;
  opacity: 1 !important;
  transform: translate(0, 0);
  transition: 0.3s opacity, 0.6s max-height;
  hyphens: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.review-container input[type="checkbox"]:checked ~ i:before {
  transform: translate(2px, 0) rotate(45deg);
}
.review-container input[type="checkbox"]:checked ~ i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}
/**/
.review-container .caption {
  font-size: 23px;
  color: #000;
  font-weight: 400;
  min-width: 255px;
  max-width: 300px;
}
.review-container .caption.required::after {
  content: "*";
  color: #f53838;
  padding-left: 3px;
}
.review-container > ul {
  display: flex;
  flex-direction: column;
}
.review-container > ul > li {
  display: flex;
  column-gap: 50px;
  align-items: center;
}
.review-container > ul > li .value {
  color: #b3b3b3;
  font-size: 23px;
  font-weight: 400;
  width: calc(100% - 350px);
}
.review-container .group-btn {
  display: flex;
  align-items: center;
  gap: 30px;
  border-top: 1px solid #e0e1e2;
  padding-top: 30px;
}
.review-container .files {
  width: 610px;
  min-height: 85px;
  border: 2px dashed #005dda;
  border-radius: 12px;
}
.review-container .teams {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: calc(100% - 350px);
}
.review-container .teams li {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 40px;
}
.review-container .teams span {
  color: #b3b3b3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.formCta-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #f8f9fa;
  height: 80px;
  width: 100%;
  padding-left: 300px;
  padding-right: 50px;
  border-top: 1px solid #b8b8b8;
  z-index: 2;
}
.formCta-container > div {
  display: flex;
  flex-wrap: nowrap;
}
.formCta-next {
  display: flex;
  width: 150px;
  height: 40px;
  align-items: center;
  border-radius: 5px;
  background: linear-gradient(91deg, #66c16d 0.28%, #22852a 69.91%);
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 34.5px */
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  transition: 0.5s;
  background-size: 100% auto;
}
.formCta-next:hover {
  background-position: right center;
  background-size: 150% auto;
}
.formCta-cancel {
  display: flex;
  width: 150px;
  height: 40px;
  align-items: center;
  border-radius: 5px;
  background: #f53838;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 34.5px */
  justify-content: center;
  align-items: center;
}
.formCta-back {
  display: flex;
  width: 150px;
  height: 40px;
  align-items: center;
  border-radius: 5px;
  background: #e4e3e3;
  color: #888;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 34.5px */
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}
.formCta-back:hover {
  background: #cbcbcb;
}
.NewIntroduce-btn {
  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-size: 35px;
  flex-shrink: 0;
  border-radius: 12px;
  background: linear-gradient(81deg, #66c16d 10.82%, #22852a 96.81%);
  line-height: 40px;
}

.businessModel-QA label {
  color: #6c6c6c !important;
  font-family: Roboto;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px !important; /* 24.173px */
  margin-left: 5px;
}

.box2 .businessModel-QA {
  width: 47% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 5px;
  cursor: pointer;
}
.intro-box {
  width: 100%;
}
.req-box {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: calc(100% - 325px);
  gap: 20px;
}
.req-box label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content !important;
}
.flexCenter {
  align-items: center;
}

/*responsive*/
@media only screen and (min-width: 0px) and (max-width: 479px) {
  .panel-profile {
    width: auto;
    padding: 0 10px 50px 10px;
  }
  .panel-profile .list-items a {
    font-size: 15px;
    display: none;
  }
  .panel-profile .list-items li {
    justify-content: center;
  }

  .panel-profile > .imgLogo {
    display: block;
    width: 30px;
  }
  .panel-profile .button {
    width: 45px;
    font-size: 13px;
    text-indent: -1000em;
    height: 40px;
  }

  .panel-profile .button img {
    display: block;
  }
  .panel-profile > img {
    display: none;
  }
  .insidePage {
    width: calc(100% - 92px);
    margin-left: 90px;
    padding-bottom: 70px;
  }

  .insidePage .wrapper {
    width: 96%;
    margin: 0;
  }
  .insidePage .form {
    row-gap: 20px;
  }
  .insidePage .wrapper .formControl > label {
    font-size: 15px;
    line-height: 28px;
    min-width: unset;
    width: 100%;
  }
  .insidePage .form .formControl {
    flex-wrap: wrap;
    gap: 10px;
  }
  .insidePage .form .formControl textarea {
    height: 100px !important;
  }
  .insidePage .form .formControl > div > label {
    font-size: 14px;
  }
  .businessModel-QA > label {
    font-size: 14px !important;
  }
  .insidePage .form .formControl .txt {
    width: 100%;
    height: 45px;
    font-size: 14px;
    padding: 0 13px;
  }
  .insidePage .form .formControl .txt.txt3 {
    width: 100%;
  }
  .insidePage .btn {
    font-size: 14px;
    width: 106px;
    height: 45px;
  }
  .insidePage .wrapper > h1 {
    font-size: 19px;
  }
  .insidePage .loading-form {
    margin-bottom: 40px;
  }
  .insidePage .wrapper .form label.label3 {
    width: 100%;
    min-width: unset;
  }

  .insidePage .form .formControl .txt.txt5 {
    width: 100%;
  }
  .insidePage .form .formControl .txt.txt2 {
    width: 100%;
  }
  .req-box {
    width: 100%;
  }
  .insidePage .form .formControl.wrap > div {
    gap: 10px;
    flex-wrap: wrap;
  }
  .NewIntroduce-btn {
    width: 32px;
    height: 32px;
    font-size: 25px;
  }
  .review-container .caption {
    font-size: 17px;
    width: auto;
    min-width: 100%;
    max-width: unset;
  }
  .review-container > ul > li {
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 13px;
  }
  .formCta-container {
    padding-left: 0;
    padding-right: 25px;
  }
  .form .formControl .box span {
    line-height: 27px;
    margin-top: 5px;
  }
  .insidePage .form .formControl.wrap > div .txt {
    font-size: 13px;
    width: 100%;
  }
  .formCta-next,
  .formCta-back {
    width: 100px;
  }
  .form .tfile {
    height: 115px;
  }
}
/*480 up to 719*/
@media only screen and (min-width: 480px) and (max-width: 719px) {
  .panel-profile {
    width: auto;
    padding: 0 10px 50px 10px;
  }
  .panel-profile .list-items a {
    font-size: 15px;
    display: none;
  }
  .panel-profile .list-items li {
    justify-content: center;
  }
  .panel-profile > img {
    display: none;
  }
  .panel-profile > .imgLogo {
    display: block;
    width: 30px;
  }
  .panel-profile .button {
    width: 65px;
    font-size: 13px;
    text-indent: -1000em;
  }

  .panel-profile .button img {
    display: block;
  }
  .insidePage {
    width: calc(100% - 107px);
    margin-left: 106px;
    padding-bottom: 70px;
  }

  .insidePage .wrapper {
    width: 96%;
    margin: 0;
  }
  .insidePage .form {
    row-gap: 20px;
  }
  .insidePage .wrapper .form label {
    font-size: 17px;
    line-height: 28px;
    min-width: unset;
    width: 100%;
  }
  .insidePage .form .formControl {
    flex-wrap: wrap;
    gap: 10px;
  }
  .insidePage .form .formControl .txt {
    width: 100%;
    font-size: 16px;
    padding: 0 13px;
  }
  .insidePage .form .formControl .txt.txt3 {
    width: 174px;
  }
  .insidePage .btn {
    font-size: 14px;
    width: 123px;
  }

  .insidePage .form .formControl .txt.txt5 {
    width: 225px;
  }
  .insidePage .form .formControl .txt.txt2 {
    width: 100%;
  }
  .insidePage .wrapper .form label.label2 {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
  .insidePage .form .formControl.wrap > div {
    gap: 10px;
  }
  .NewIntroduce-btn {
    width: 32px;
    height: 32px;
    font-size: 25px;
  }
  .review-container .caption {
    font-size: 17px;
    width: auto;
    min-width: 100px;
    max-width: unset;
  }
  .review-container > ul > li {
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 13px;
  }
  .formCta-container {
    padding-left: 0;
    padding-right: 25px;
  }
  .form .formControl .box span {
    line-height: 27px;
  }
  .insidePage .form .formControl.wrap > div .txt {
    font-size: 13px;
  }
}
/*720 up to 1000*/
@media only screen and (min-width: 720px) and (max-width: 1000px) {
  .panel-profile {
    width: 220px;
    padding: 0 15px 70px 15px;
  }
  .panel-profile .list-items a {
    font-size: 15px;
  }
  .insidePage {
    width: calc(100% - 260px);
    margin-left: 250px;
    padding-bottom: 70px;
  }
  .insidePage .wrapper {
    width: 96%;
    margin: 0;
  }
  .insidePage .wrapper .form label {
    font-size: 17px;
    line-height: 28px;
    min-width: 100%;
  }
  .insidePage .form .formControl {
    flex-wrap: wrap;
    gap: 10px;
  }
  .insidePage .form .formControl .txt {
    width: 100%;
    font-size: 16px;
    padding: 0 13px;
  }
  .insidePage .form .formControl .txt.txt3 {
    width: 219px;
  }
  .insidePage .btn {
    font-size: 14px;
    width: 125px;
  }

  .insidePage .form .formControl .txt.txt5 {
    width: 315px;
  }
  .insidePage .form .formControl .txt.txt2 {
    width: 100%;
  }
  .insidePage .wrapper .form label.label2 {
    width: 100%;
    min-width: unset;
  }
  .insidePage .form .formControl.wrap > div {
    gap: 10px;
  }
  .NewIntroduce-btn {
    width: 32px;
    height: 32px;
    font-size: 25px;
  }
  .review-container .caption {
    font-size: 17px;
    width: auto;
    min-width: 100px;
    max-width: unset;
  }
  .review-container > ul > li {
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 13px;
  }
}
/*1001 up to 1249*/
@media only screen and (min-width: 1001px) and (max-width: 1249px) {
  .insidePage .wrapper {
    width: 660px;
  }
  .insidePage .form .formControl .txt.txt3 {
    width: 192px;
  }
}

.progress {
  width: 100%;
  height: 5px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.progress-bar {
  height: 100%;
  width: 0;
  background-color: #4caf50;
  text-align: center;
  line-height: 30px;
  color: white;
  transition: all 0.3s ease-in;
}

@media only screen and (max-width: 700px) {
  .formCta-container > div .button {
    transform: scale(0.7);
  }
}
