.mintModlaContainer {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: #0000003b;
  justify-content: center;
  z-index: 71;
}
.mintModal {
  width: 350px;
  min-height: 433px;
  background: #ffff;
  padding: 20px 30px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.mintModal > h4 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
}
.mintModal > h5 {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 100;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mintModal > h6 {
  /* margin-top: -10px; */
  font-size: 10px;
  font-weight: 100;
  /* margin-bottom: 30px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -20px;
}
.mintModal .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  cursor: pointer;
}

.mintModal > p {
  padding-bottom: 15px;
  font-size: 90%;
  border-bottom: 2px solid #0000003b;
}

.FactorServiceFee {
  display: flex;
  width: 80%;
  justify-content: space-between;
  height: auto;
}
.overflowFactorServiceFee {
  overflow-x: auto;
  padding: 8px 0;
}
.overflowFactorServiceFee::-webkit-scrollbar {
  height: 5px;
}
.DiscountAgreement {
  color: rgb(0, 255, 170);
  padding: 0;
  margin: 0;
}
.DiscountAgreement-btn {
  background: none;
  border: none;
  padding: 4px;
  border-radius: 6px;
  transition: background 0.3s ease;
  cursor: pointer;
}
.DiscountAgreement-btn:hover {
  background: rgb(0 130 255);
}
.DiscountAgreement-btn > img {
  max-width: 80px;
}
.DiscountAgreement-remove {
  position: absolute;
  color: white;
  font-size: 10px;
  right: -5px;
  top: -6px;
  background: #ff5d5d;
  /* border: 1px solid #2b2b2b; */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding-left: 1px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding-top: 2px;
}
.DiscountAgreement-add {
  position: absolute;
  color: white;
  font-size: 10px;
  right: -5px;
  top: -6px;
  background: #006912;
  /* border: 1px solid #2b2b2b; */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding-left: -1px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  opacity: 0; /* Start with 0 opacity */
  transition: opacity 0.3s ease; /* Add the transition */
  padding-top: 2px;
}
.DiscountAgreementContainer:hover .DiscountAgreement-add {
  opacity: 1;
}

.apllication-mint-btn {
  width: 100%;
  background: linear-gradient(to right, #66c16d 0%, #22852a 100%);
  color: #fff;
  padding: 10px 0;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-disable {
  filter: grayscale(5);
}
