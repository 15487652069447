.particle-container {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #66c16d00 0%, #22852a45 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.particle-container .particle {
  width: 350px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: ; */
}

.particle button {
  width: 100%;
  background: linear-gradient(to right, #66c16d 0%, #22852a 100%);
  color: #fff;
  padding: 10px 0;
  border-radius: 6px;
  cursor: pointer;
}
