
.tab--button{
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 35px;
}
.tab--button .button{
    width: 200px;height: 40px;
    border: 2px solid #22852A ;
    border-radius: 6px;
    font-size: 15px;
    font-weight: bold;
    color: #22852A;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease;
}
.tab--button .button.active{
    background-image: linear-gradient( to right, rgba(115, 217, 130, 1) 0%, rgba(6, 108, 21, 1) 100% );
    border: none;
    color: #fff;
}
.wrapper{
    width: 100%;
}
.assets-box{
    padding-bottom: 40px;
}
.assets-box .head-info{
    background: #F5F8FA;
    display:table-row;
    width:100%
}
.assets-box .head-info span{
    font-size: 19px;
    color: #3F4254;
    display: table-cell;
    padding: 15px 0;
    border-bottom: 1px solid #EFF2F5;
    border-top: 1px solid #EFF2F5;
}
.assets-box .head-info span:first-child{
    padding-left: 35px;
}   
.assets-box .body-assets{
    display: table-row;
    width:100% 
}
.assets-box .body-assets .items{
    display: table-cell;
    vertical-align: middle;
    padding: 15px 0;
    border-bottom: 1px solid #F5F8FA;

}

.assets-box .body-assets .items img{
    width: 40px;
    height: 40px;
    border-radius: 6px;
}
.assets-box .body-assets .items *{ 
    font-size: 16px;
    color: #7E8299;
}
.assets-box  .body-assets .items:first-child{
    padding-left: 35px;
}
.assets-box .body-assets .items .name{
    font-size: 14px;
    color: #2D3748;
}
.assets-box .body-assets .items .value{
    font-size: 13px;
    color: #718096;
}


@media only screen and (min-width: 0px) and (max-width: 479px) {
}

@media only screen and (min-width: 720px) and (max-width: 1000px) {
    .wrapper{overflow-x: scroll;}
}