.walletContainer-walletPage {
  transform: translateY(50px);
  height: 100vh;
  padding-left: 10px;
}
.walletHeader-walletPage > h2 {
  color: #3f4254;
  font-family: Poppins;
  font-size: 24.07px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
.walletHeader-walletPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  margin-bottom: 15px;
}
.banner-refrral {
  position: relative;
}
.banner-refrral > h2 {
  color: #fff;
  text-align: justify;
  font-family: Poppins;
  font-size: 60.149px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.781px; /* 59.59% */
  position: absolute;
  text-align: center;
  top: 42%;
  width: 100%;
}
.banner-refrral > h3 {
  position: absolute;
  text-align: center;
  top: 60%;
  width: 100%;
  color: #fff;
  font-family: Poppins;
  font-size: 35.723px;
  font-style: normal;
  font-weight: 300;
  line-height: 44.781px; /* 102.421% */
}
.banner-refrral > img {
  max-width: 100%;
}
.searchContainer-reffral {
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 10px 15.399999618530273px rgba(0, 0, 0, 0.25));
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translateX(-50%);
  width: 580px;
}
.searchContainer-reffral > div {
  border-top-left-radius: 16px;
  padding: 9.4px 33.5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 16px;
  background: #fff;
  box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.05);
  color: #8a8080;
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32.775px; /* 204.842% */
}
.searchContainer-reffral > button {
  border-radius: 0px 16px 16px 0px;
  background: #22842a;
  display: inline-flex;
  padding: 15.5px 33.5px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.socialShareContainer-refrral {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  flex-wrap: wrap;
  margin-top: 70px;
}
.SocialmediaLink {
  border-right: 1px solid #cecece !important;
  padding-right: 50px;
}
.SocialmediaLink > h4 {
  color: #242629;
  text-align: justify;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.775px; /* 109.249% */
}
.SocialmediaLink > h5 {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 40px;
}
.SocialmediaLink > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.SocialmediaLink > div > button {
  width: 52px;
  height: 52px;
  margin-right: 8px;
  border: 1px solid #cecece !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: none;
}
.Readeem-reffrral {
  padding-left: 50px;
}
.Readeem-reffrral > h4 {
  color: #242629;
  text-align: justify;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.775px; /* 109.249% */
}
.Readeem-reffrral > div {
  border-radius: 4px;
  border: 1px solid #ffac33;
  background: #fff;
  color: #ffac33;
  text-align: center;
  font-family: Poppins;
  font-size: 16.066px;
  font-style: normal;
  font-weight: 600;
  padding: 15px 8px;
  line-height: 32.909px; /* 204.842% */
  margin-top: 16px;
}
.Readeem-reffrral > button {
  display: flex;
  width: 149px;
  padding: 10.224px 33.059px;
  justify-content: center;
  align-items: center;
  gap: 11.02px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14.326px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 2.897px;
  background: linear-gradient(to right, #66c16d 0%, #22852a 100%);
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1000px) {
  .banner-refrral-header {
    font-size: 90% !important;
  }
}
@media (max-width: 765px) {
  .banner-refrral-header {
    font-size: 50%;
  }
  .tokenHeader-walletPage {
    justify-content: space-between;
  }
  .tokenHeader-walletPage > span {
    margin-right: 0;
  }
  .rowTokenList-walletPage {
    justify-content: space-between;
  }
  .socialShareContainer-refrral {
    margin-top: 50px;
  }
  .searchContainer-reffral > div {
    padding: 0.40000000000000036px 33.5px;
    font-size: 9px;
  }
  .SocialmediaLink > h4 {
    display: none;
  }
  .Readeem-reffrral > h4 {
    display: none;
  }
  .SocialmediaLink > h5 {
    display: none;
  }
  .searchContainer-reffral {
    width: 350px;
  }
  .searchContainer-reffral > button {
    padding: 11.5px 11px;
    text-align: center;
    font-size: 8px;
    white-space: nowrap;
  }
  .SocialmediaLink {
    border-right: none !important;
    padding-right: 0px;
  }
  .banner-refrral > h2 {
    font-size: 20.149px;
    top: 20%;
  }
  .banner-refrral > h3 {
    top: 50%;
    font-size: 10.723px;
  }
  .Readeem-reffrral {
    width: 100%;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Readeem-reffrral > div {
    font-size: 10.066px;
    font-style: normal;
    padding: 4px 8px;
    margin-top: 16px;
    margin-right: 10px;
  }
  .Readeem-reffrral > button {
    width: 96px;
    height: 41.837px;
  }
  .SocialmediaLink > div > button {
    width: 40px;
    height: 40px;
  }
  .image-hide-on-mobile {
    display: none;
  }
}
