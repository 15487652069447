.wallet {
  width: 140px;
  height: 50px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: linear-gradient(to right, #66c16d 0%, #22852a 100%);

  position: absolute;
  top: 45px;
  right: 45px;
}

.wallet span {
  font-size: 14px;
  color: white;
  font-weight: 700;
}

.wallet-info-container {
  display: none;
  position: fixed;
  z-index: 25;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.wallet-info {
  position: relative;
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 20px;
  width: 300px;
  height: 360px;
  animation: slide-in 0.3s ease;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.wallet-header {
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  text-align: center;
}

.copy-icon:active {
  transform: translateY(1px);
}
.copy-icon {
  border-radius: 50%;
  box-shadow: 0px 4px 6.699999809265137px 0px #00000040;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  transition: all 0.5s;
}
@media only screen and (max-width: 600px) {
  .wallet-info {
    /* max-width: 90%; */
  }
  .wallet {
    width: fit-content;
    padding: 5px 10px;
  }
  .wallet .wallet-icon {
    width: 30px;
  }
  .wallet span {
    display: none;
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.show {
  display: block;
  opacity: 1;
}

@keyframes slide-in {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.wallet-address {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
