.action-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #afafaf;
  border-radius: 12px;
  background-color: rgba(240, 248, 255, 0.427);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
}

.auditor-modal-action {
  width: 500px;
  height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  gap: 30px;
}
.auditor-modal-action .modal-actions {
  display: flex;
  justify-content: space-between;
}
.auditor-modal-action .modal-actions button {
  padding: 5px 15px;
  border-radius: 5px;
}
.auditor-modal-action textarea {
  border: 0.5px solid #929292;
  height: 50%;
  border-radius: 12px;
  padding: 5px;
}
